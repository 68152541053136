import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Noir-Tattoo-Studio
			</title>
			<meta name={"description"} content={"Entfesseln Sie Ihren inneren Künstler im Noir-Tattoo-Studio"} />
			<meta property={"og:title"} content={"Noir-Tattoo-Studio"} />
			<meta property={"og:description"} content={"Entfesseln Sie Ihren inneren Künstler im Noir-Tattoo-Studio"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/4268260.png?v=2024-07-11T13:29:40.441Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text" />
		</Components.Header>
		<Section background="--color-dark" padding="80px 0 0 0">
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				padding="0px 100px 0px 100px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 30px 0px"
					font="--headline1"
					color="--light"
					text-align="center"
					sm-font="normal 700 62px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="100%"
				>
					Sind Sie bereit, Ihre Vision in die Realität umzusetzen?
				</Text>
				<Text margin="0px 0px 48px 0px" font="--lead" color="--light" text-align="center">
					Machen Sie den ersten Schritt zu Ihrem neuen Tattoo, indem Sie das Noir-Tattoo-Studio besuchen. Unsere talentierten Künstler freuen sich darauf, mit Ihnen zusammenzuarbeiten und ein Kunstwerk zu schaffen, das Sie ein Leben lang mit Stolz tragen werden. Vereinbaren Sie noch heute einen Termin und wir helfen Ihnen dabei, Kunstwerke zu kreieren, Mascara für Mascara.
				</Text>
				<Link
					href="+493094052616"
					text-align="center"
					color="--light"
					font="--headline3"
					text-decoration-line="initial"
					margin="0px 0px 16px 0px"
				>
					+493094052616
				</Link>
				<Text margin="0px 0px 48px 0px" font="--headline3" color="--light" text-align="center">
					Fontanestraße 25, 12049 Berlin, Germany
				</Text>
			</Box>
		</Section>
		<Section
			padding="60px 0 60px 0"
			background="linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(https://uploads.quarkly.io/668fb61b1d712a00233610e8/images/ftoe4hn4jklc1.webp?v=2024-07-11T13:29:40.435Z) center center/cover no-repeat"
			min-height="40vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline3"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
					text-transform="uppercase"
				>
					Kontaktieren Sie uns oder besuchen Sie noch heute unser Studio, um mehr über alle unsere Angebote zu erfahren
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="text2" />
			<Override slot="text3" />
			<Override slot="text1" />
			<Override slot="text" />
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});